import { useTheme, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "redux/slice/loadingSlice";
import moment from "moment";

import SectionTitle from "Common/SectionTitle";
import { useStyles } from "Document/DocumentDecrypted/style";
import { documentHelper } from "helpers";

const currentDocStyle = { cursor: "pointer", fontWeight: 600 };
const normalDocStyle = { cursor: "pointer" };

export default function InboxReply({ data, currentId }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [expand, setExpand] = useState(true);
  const [open, setOpen] = useState(true);

  async function openDocInBlankPage(docId) {
    try {
      if (!docId) {
        alert("No Document");
        return;
      }
      dispatch(setLoading(true));
      const blob = await documentHelper.decryptDocument(docId)
      var url = URL.createObjectURL(blob);
      window.open(url + "#page=1&view=FitH", docId, `popup, width=${window.screen.width * 0.54}, height=${window.screen.width}`);
      dispatch(setLoading(false));
    } catch (e) {
      alert('File Not Found');
      dispatch(setLoading(false));
    }
  }
  function renderTopLevel() {
    const topLevel = data?.topLevelBeReplied
    return <div className="flex-col">
      {topLevel.map((document, idx) => {
        const displayName = (document.letterDate && `${moment(document.letterDate).format("yyyy-MM-DD")} - `) + document.displayName
        return <div
          key={idx}
          className={classes.ellipsis}
          style={currentId === data.id ? currentDocStyle : normalDocStyle}
          title={displayName}
          onClick={() => openDocInBlankPage(document.documentId)}>
          {document.letterDate && displayName}
        </div>
      })}
    </div>
  }
  return (
    data && <Accordion className={classes.details} expanded={open}>
      <AccordionSummary
        onClick={() => setOpen(!open)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="replied-content"
        id="replied-header"
      >
        <SectionTitle title={"Replied By / Reply To Document"} fontStyle={theme.typography.h4} />
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column" }}>
        <div className={classes.row}>
          <span className={classes.expandToggle} onClick={() => setExpand(!expand)}>{expand ? "-" : "+"}</span>
          {renderTopLevel()}
        </div>
        {expand &&
          data?.repliedBy?.map((item, idx) => <ReplyExpand data={item} key={idx} openDocInBlankPage={openDocInBlankPage} currentId={currentId} />)
        }
      </AccordionDetails>
    </Accordion>
  );
}

function ReplyExpand({ data, openDocInBlankPage, currentId }) {
  const [expand, setExpand] = useState(true);
  const theme = useTheme();
  const classes = useStyles(theme);
  const displayName = (data.letterDate && `${moment(data.letterDate).format("yyyy-MM-DD")} - `) + data.displayName
  function renderRecordItem() {
    return <div className={classes.ellipsis}>
      <div
        title={displayName}
        className={classes.ellipsis} style={currentId === data.fileMetaId ? currentDocStyle : normalDocStyle}
        onClick={() => openDocInBlankPage(data.documentId)}>
        {displayName}
      </div>
      {data?.additionBeReplied?.map((record, idx) => {
        const additionalDisplayName = `${moment(record.letterDate).format('yyyy-MM-DD')} - ` + record.displayName
        return <div className={classes.ellipsis} style={normalDocStyle} onClick={() => openDocInBlankPage(record.documentId)} key={idx} title={additionalDisplayName}>{additionalDisplayName}</div>
      })}
    </div>
  }
  return (
    <div style={{ marginLeft: "16px", flexDirection: "column" }}>
      <div className={classes.row}>
        {data?.repliedBy?.length > 0
          ? <span className={classes.expandToggle} onClick={() => setExpand(!expand)}>{expand ? "-" : "+"}</span>
          : <span className={classes.expandToggle}>-</span>
        }
        {renderRecordItem()}
      </div>
      {expand &&
        data?.repliedBy?.map((item, idx) =>
          <ReplyExpand data={item} key={idx} openDocInBlankPage={openDocInBlankPage} currentId={currentId} />
        )
      }
    </div>
  );
}
